.mt_120 {
  margin-top: 120px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_12 {
  margin-top: 12px;
}

.mt_8 {
  margin-top: 8px;
}

.mt_4 {
  margin-top: 4px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_8 {
  margin-bottom: 8px;
}
