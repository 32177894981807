@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");

@font-face {
  font-family: "NanumPenScript"; /* 사용할 폰트 이름 */
  src: url("./fonts/NanumPenScript-Regular.ttf") format("truetype"); /* 폰트 파일 경로와 형식 지정 */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DoHyeon"; /* 사용할 폰트 이름 */
  src: url("./fonts/DoHyeon-Regular.ttf") format("truetype"); /* 폰트 파일 경로와 형식 지정 */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans"; /* 사용할 폰트 이름 */
  src: url("./fonts/NotoSansKR-VariableFont_wght.ttf") format("truetype"); /* 폰트 파일 경로와 형식 지정 */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stylish"; /* 사용할 폰트 이름 */
  src: url("./fonts/Stylish-Regular.ttf") format("truetype"); /* 폰트 파일 경로와 형식 지정 */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family:
    "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    "Helvetica Neue",
    "Segoe UI",
    "Apple SD Gothic Neo",
    "Noto Sans KR",
    "Malgun Gothic",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "NanumPenScript",
    "DoHyeon",
    "NotoSans",
    "Stylish",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  user-select: none; /* 모든 요소의 텍스트 선택을 방지 */
  -webkit-user-select: none; /* Safari 등의 웹킷 기반 브라우저에 적용 */
  -moz-user-select: none; /* Firefox에 적용 */
  -ms-user-select: none; /* IE10+에 적용 */
  touch-action: pan-x pan-y;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: "Pretendard", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

button {
  font-family: "Pretendard", Cascadia, monospace;
  outline: none !important;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  background-color: #121212;
}

* {
  box-sizing: border-box;
}

textarea {
  padding: 0;
}

div.header {
  background: #2e2e2e !important;
  color: #fff !important;
  padding: 24px !important;
}

div.content {
  background: #2e2e2e !important;
  color: #fff !important;
  padding: 0 24px !important;
  padding-top: 16px !important;
}

div.actions {
  background: #2e2e2e !important;
  color: #fff !important;
  padding: 24px !important;
}

div.actions button {
  width: 100px;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

div.actions button:nth-child(2) {
  margin-left: 12px;
}
